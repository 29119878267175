@import url(https://fonts.googleapis.com/css?family=Boogaloo|Lato|Comfortaa:300,400,600,700);
* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  box-sizing: border-box; }

body {
  font-family: "Lato", "sans-serif", "sans-serif";
  font-weight: 400; }

.auth-container {
  display: flex;
  min-height: 100vh; }

.auth-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.auth-right-panel {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #2185d0;
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%); }

.auth__text-box {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* for the button, we used inline-block */ }

.auth-primary {
  color: #fff;
  text-transform: uppercase;
  /*
        hide this when animation within the element is in process
        otherwise, there's a little "shaking"
    */
  backface-visibility: hidden;
  margin-bottom: 6rem; }

.auth-primary--logo {
  animation-name: moveInTop;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  margin-bottom: 3rem;
  max-width: 30rem; }

/* Put span one under another: block element, span is by default inline*/
.auth-primary--main {
  display: block;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: .5rem;
  animation-name: moveInLeft;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  margin-bottom: 3rem;
  /* 
    animation-delay: 3s;
    animation-iteration-count: 3;
     */ }

.auth-primary--sub {
  display: block;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .2rem;
  animation-name: moveInRight;
  animation-duration: 2s; }

.auth__company-info {
  text-align: center;
  color: #fff; }
  .auth__company-info__link {
    color: #fff; }

.auth-form {
  max-width: 35rem;
  width: 80%;
  height: 30%;
  padding: 2rem 2rem;
  margin-top: -20%; }

@keyframes moveInLeft {
  /* 0% Start of animation */
  0% {
    opacity: 0;
    /* Invisible */
    transform: translateX(-10rem);
    /* Move in from the left (negative value) */ }
  80% {
    transform: translate(2rem); }
  /* 100% End of animation */
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  /* 0% Start of animation */
  0% {
    opacity: 0;
    /* Invisible */
    transform: translateX(10rem);
    /* Move in from the left (negative value) */ }
  80% {
    transform: translate(-2rem); }
  /* 100% End of animation */
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInTop {
  /* 0% Start of animation */
  0% {
    opacity: 0;
    /* Invisible */
    transform: translateY(-10rem);
    /* Move in from the left (negative value) */ }
  /* 100% End of animation */
  100% {
    opacity: 1;
    transform: translate(0); } }

/* ------------------ Main Application ------------------ */
@keyframes moveInTop {
  /* 0% Start of animation */
  0% {
    opacity: 0;
    /* Invisible */
    transform: translateY(-3rem);
    /* Move in from the left (negative value) */ }
  /* 100% End of animation */
  100% {
    opacity: 1;
    transform: translate(0); } }

.lara-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #e6ecf3; }

.lara-content {
  display: flex;
  flex: 1; }
  .lara-content__component {
    flex: 0 0 85%; }
  .lara-content__component-nosidebar {
    flex: 0 0 100%; }
  .lara-content .lara-header {
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #596B8C; }
    .lara-content .lara-header__toggle {
      padding-left: 1rem; }
    .lara-content .lara-header__toggle-btn {
      border: none;
      background-color: inherit;
      color: #fff; }
      .lara-content .lara-header__toggle-btn:focus {
        outline: none; }
      .lara-content .lara-header__toggle-btn:active {
        transform: translateY(2px); }
    .lara-content .lara-header__selector {
      padding-right: 1rem;
      min-width: 20rem; }

.lara-sidebar {
  flex: 0 0 15%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .lara-sidebar__top {
    display: flex;
    flex-direction: column; }
  .lara-sidebar__logo {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: .5rem; }
  .lara-sidebar__nav-wrapper {
    padding-top: 1rem;
    padding-left: .5rem;
    padding-right: .5rem; }
  .lara-sidebar__nav {
    font-weight: 700;
    overflow-y: hidden;
    letter-spacing: .02rem;
    font-family: "Comfortaa", "sans-serif"; }
  .lara-sidebar .lara-nav-item {
    position: relative;
    line-height: 2.5rem;
    padding: 0;
    white-space: nowrap;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding-top: .1rem;
    padding-bottom: .1rem; }
    .lara-sidebar .lara-nav-item > a {
      color: #87888a; }
    .lara-sidebar .lara-nav-item > a:hover {
      color: #2185d0; }
    .lara-sidebar .lara-nav-item-sub {
      margin-left: 2rem; }
  .lara-sidebar .lara-active-nav-icon {
    height: 1.1rem;
    vertical-align: middle;
    fill: #2185d0; }
  .lara-sidebar .lara-nav-icon {
    height: 1.1rem;
    vertical-align: middle;
    fill: #87888a; }
  .lara-sidebar .lara-nav-item-name {
    padding-left: .5rem; }
  .lara-sidebar .lara-active {
    color: #2185d0;
    fill: #2185d0; }
  .lara-sidebar .lara-nav-sub {
    display: flex;
    flex-direction: row; }
  .lara-sidebar .lara-nav-header-wrapper {
    justify-content: space-between;
    align-items: center; }
    .lara-sidebar .lara-nav-header-wrapper .lara-nav-header {
      color: #617496;
      fill: #617496; }
  .lara-sidebar__user {
    padding-bottom: 1rem; }

.lara-nav-profile {
  display: flex;
  padding: .75rem .5rem .75rem .5rem;
  color: #686868;
  transition-duration: .45s; }

.lara-profile-image {
  position: relative;
  width: 2rem;
  height: 2rem; }

.lara-avatar {
  height: 2.5rem; }

.lara-profile-user {
  flex: 1;
  margin-left: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .lara-profile-user__name {
    font-size: 1.2rem;
    font-weight: 600; }
  .lara-profile-user__action {
    font-size: .75rem; }
  .lara-profile-user .lara-profile-left {
    display: flex;
    flex-direction: column; }
  .lara-profile-user .lara-profile-right .lara-profile-icon {
    height: 1.1rem;
    vertical-align: middle;
    fill: #2185d0; }

.lara-component-wrapper {
  padding: .5rem 1.5rem; }

.lara-secondary-segment-wrapper {
  padding-left: 1rem; }

.lara-hidden {
  display: none; }

.lara-right {
  float: right; }

.lara-logo {
  height: 3.25rem; }

.lara-has-bottom-border {
  border-bottom: 1px solid #617496; }

.lara-clickable {
  cursor: pointer; }

.lara-tooltip-title {
  text-decoration: underline gray dotted; }

.lara-product-tooltip {
  color: #617496;
  cursor: pointer; }

.lara-editable {
  color: #2185d0; }

.lara-breadcrub {
  margin-bottom: 1rem; }

.lara-segment-ribbon {
  margin-bottom: .5rem; }

.lara-tooltip-list-holder {
  max-width: 15rem; }

.lara-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline;
  cursor: pointer; }

.lara-upload-btn {
  border: .2rem solid gray;
  color: gray;
  background-color: white;
  padding: .5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer; }

.lara-upload-btn-wrapper input[type=file] {
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer; }

.lara-full-space {
  margin: 1rem 1rem; }

.lara-progress-wrapper {
  width: 50%; }

.lara-text-block {
  display: block;
  margin-bottom: .5rem; }

.lara-text-label {
  display: block;
  font-family: "Boogaloo", "sans-serif"; }

.lara-text-value {
  display: block;
  font-weight: 600; }

.lara-ui-label {
  display: block;
  margin: 0 0 .28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none; }

.lara-table-header-icon {
  cursor: pointer; }

.lara-rendered-content-holder {
  min-height: 80vh; }

.lara-row-margin {
  margin-top: 1rem; }

.lara-row-bottom-margin {
  margin-bottom: 1rem; }

.lara-filter-name {
  display: block;
  padding: .5rem 0;
  font-weight: 600;
  font-family: "Comfortaa", "sans-serif"; }

.lara-single-line-text {
  display: block; }

.lara-quarantined {
  color: #f48642; }

.lara-full-width .input {
  width: 100%; }

.lara-inline-span {
  display: block; }

.lara-success-text {
  color: #2ecc40; }

.lara-voided-tracking {
  text-decoration: line-through; }

.lara-token-wrap {
  width: 100%;
  word-wrap: break-word;
  display: inline-block; }

.lara-chart {
  height: 350px; }

.lara_card_image {
  padding: 20px; }

.ui.search > .results {
  overflow-y: auto;
  max-height: 200px; }

.lara-pdf-viewer {
  width: 100%;
  height: 80vh; }

.lara-white-font {
  color: antiquewhite; }

.lara-order-line-comment {
  color: #2185d0;
  font-size: .8em; }
