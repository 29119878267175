$main-font: 'Lato', 'sans-serif';   
$display-font: 'Boogaloo', 'sans-serif';
$menu-font: 'Comfortaa', 'sans-serif';
$label-font: 'Boogaloo', 'sans-serif';
$primary-color: #2185d0;
$secondary-color: #617496;
$content-bg: #e6ecf3;
$header-bg: #596B8C;
$no-color: #fff;
$inactive-link: #87888a;
$quarantined-qty: #f48642;