/* ------------------ Main Application ------------------ */
@keyframes moveInTop {
  /* 0% Start of animation */
  0% {
      opacity: 0; /* Invisible */
      transform: translateY(-3rem); /* Move in from the left (negative value) */
  }

  /* 100% End of animation */
  100% {
      opacity: 1;
      transform: translate(0);
  }
}

.lara-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $content-bg;
}

.lara-content {
  display: flex;
  flex: 1;
  
  &__component {
    flex: 0 0 85%;
  }

  &__component-nosidebar {
    flex: 0 0 100%;
  }

  .lara-header {
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $header-bg;
    &__toggle {
      padding-left: 1rem;
    }
    &__toggle-btn {
      border: none;
      background-color: inherit;
      color: $no-color;

      &:focus {
          outline: none;
      }

      &:active {
          transform: translateY(2px);
      }
    }
    &__selector {
      padding-right: 1rem;
      min-width: 20rem;
    }
  }
}
.lara-sidebar {
  flex: 0 0 15%;
  background-color: $no-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__top {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: .5rem;
  }

  &__nav-wrapper {
    padding-top: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }

  &__nav {
    font-weight: 700;
    overflow-y: hidden;
    letter-spacing: .02rem;
    font-family: $menu-font
  }

  .lara-nav-item {
    position: relative;
    line-height: 2.5rem;
    padding: 0;
    white-space: nowrap;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding-top: .1rem;
    padding-bottom: .1rem;
    & > a {
      color: $inactive-link;
    }

    & > a:hover {
      color: $primary-color;
    }

    &-sub {
      margin-left: 2rem;
    }
  }

  .lara-sub-nav-item {
    // animation-name: moveInTop;
    // animation-duration: .5s;
  }

  .lara-active-nav-icon {
    height: 1.1rem;
    vertical-align: middle;
    fill: $primary-color;
  }

  .lara-nav-icon {
    height: 1.1rem;
    vertical-align: middle;
    fill: $inactive-link;
  }

  .lara-nav-item-name {
    padding-left: .5rem;
  }

  .lara-active {
    color: $primary-color;
    fill: $primary-color;
  }

  .lara-nav-sub {
    display: flex;
    flex-direction: row;
  }

  .lara-nav-header-wrapper {
    justify-content: space-between;
    align-items: center;
    .lara-nav-header {
      color: $secondary-color;
      fill: $secondary-color;
    }
  }

  &__user {
    padding-bottom: 1rem;
  }
}

.lara-nav-profile {
  display: flex;
  padding: .75rem .5rem .75rem .5rem;
  color: #686868;
  transition-duration: .45s;
}

.lara-profile-image {
  position: relative;
  width: 2rem;
  height: 2rem;
}

.lara-avatar {
  height: 2.5rem;
}

.lara-profile-user {
  flex: 1;
  margin-left: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__name {
    font-size: 1.2rem;
    font-weight: 600;
  }

  &__action {
    font-size: .75rem
  }

  .lara-profile-left {
    display: flex;
    flex-direction: column;
  }
  
  .lara-profile-right {
    // align-items: center;
    // align-content: center;
    .lara-profile-icon {
      height: 1.1rem;
      vertical-align: middle;
      fill: $primary-color;
    }
  }
}

.lara-component-wrapper {
  padding: .5rem 1.5rem;
}
.lara-secondary-segment-wrapper {
  padding-left: 1rem
}
.lara-hidden {
  display: none;
}
.lara-right {
  float: right;
}
.lara-logo {
  height: 3.25rem;
}
.lara-has-bottom-border {
  border-bottom: 1px solid $secondary-color
}
.lara-clickable {
  cursor: pointer;
}
.lara-tooltip-title {
  text-decoration: underline gray dotted
}
.lara-product-tooltip {
  //text-decoration: underline gray dotted;
  color: $secondary-color;
  cursor: pointer;
}
.lara-editable {
  color: $primary-color
}
.lara-breadcrub {
  margin-bottom: 1rem;
}
.lara-segment-ribbon {
  margin-bottom: .5rem;
}
.lara-tooltip-list-holder {
  max-width: 15rem;
}

.lara-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline;
  cursor: pointer;
}

.lara-upload-btn {
  border: .2rem solid gray;
  color: gray;
  background-color: white;
  padding: .5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.lara-upload-btn-wrapper input[type=file] {
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.lara-full-space {
  margin: 1rem 1rem
}

.lara-progress-wrapper {
  width: 50%
}

.lara-text-wrapper {
  //margin-bottom: .5rem;
}

.lara-text-block {
  display: block;
  margin-bottom: .5rem;
}

.lara-text-label {
  display: block;
  font-family: $label-font
}

.lara-text-value {
  display: block;
  font-weight: 600;
}

.lara-ui-label {
  display: block;
  margin: 0 0 .28571429rem 0;
  color: rgba(0,0,0,.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
}

.lara-table-header-icon {
  cursor: pointer;
}

.lara-rendered-content-holder {
  min-height: 80vh
}

.lara-row-margin {
  margin-top: 1rem;
}

.lara-row-bottom-margin {
  margin-bottom: 1rem;
}

.lara-filter-name {
  display: block;
  padding: .5rem 0;
  font-weight: 600;
  font-family: $menu-font
}

.lara-single-line-text {
  display: block
}

.lara-quarantined{
  color: $quarantined-qty
}

.lara-full-width .input {
  width: 100%
}

.lara-inline-span {
  display: block
}

.lara-success-text {
  color: #2ecc40
}

.lara-voided-tracking {
  text-decoration: line-through
}

.lara-token-wrap {
  width:100%; 
  word-wrap:break-word; 
  display:inline-block;
}

.lara-chart {
  height: 350px;
}

.lara_card_image {
  padding: 20px;
}

.ui.search > .results {
  overflow-y: auto;
  max-height: 200px;
}

.lara-pdf-viewer {
  width: 100%;
  height: 80vh;
}

.lara-white-font {
  color: antiquewhite
}

.lara-order-line-comment {
  color: $primary-color;
  font-size: .8em
}