// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Boogaloo|Lato|Comfortaa:300,400,600,700);
* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  font-family: $main-font, 'sans-serif';
  font-weight: 400;
}