.auth-container {
    display: flex;
    min-height: 100vh;
}

.auth-left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.auth-right-panel {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $primary-color;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
}

.auth__text-box {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; /* for the button, we used inline-block */
}

.auth-primary  {
    color: $no-color;
    text-transform: uppercase;
    /*
        hide this when animation within the element is in process
        otherwise, there's a little "shaking"
    */
    backface-visibility: hidden;
    margin-bottom: 6rem;
}

.auth-primary--logo {
    animation-name: moveInTop;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    margin-bottom: 3rem;
    max-width: 30rem;
}

/* Put span one under another: block element, span is by default inline*/
.auth-primary--main {
    display: block;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: .5rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    margin-bottom: 3rem;
    /* 
    animation-delay: 3s;
    animation-iteration-count: 3;
     */
}

.auth-primary--sub {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2rem;
    animation-name: moveInRight;
    animation-duration: 2s;
}
.auth_branding {
    
}
.auth__company-info {
    text-align: center;
    color: $no-color;

    &__link {
        color: $no-color
    }
}

.auth-form {
    max-width: 35rem;
    width: 80%;
    height: 30%;
    padding: 2rem 2rem;
    margin-top: -20%;
}

@keyframes moveInLeft {
    /* 0% Start of animation */
    0% {
        opacity: 0; /* Invisible */
        transform: translateX(-10rem); /* Move in from the left (negative value) */
    }

    80% {
        transform: translate(2rem);
    }

    /* 100% End of animation */
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    /* 0% Start of animation */
    0% {
        opacity: 0; /* Invisible */
        transform: translateX(10rem); /* Move in from the left (negative value) */
    }

    80% {
        transform: translate(-2rem);
    }

    /* 100% End of animation */
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInTop {
    /* 0% Start of animation */
    0% {
        opacity: 0; /* Invisible */
        transform: translateY(-10rem); /* Move in from the left (negative value) */
    }

    /* 100% End of animation */
    100% {
        opacity: 1;
        transform: translate(0);
    }
}
